// Warframe 1999 - Techrot Encore
// Theme for Techrot Encore homepage takeover

$COLOR_TECHROT_BACKGROUND: #0F0000;
$COLOR_TECHROT_PANEL: #181B1A;
$COLOR_TECHROT_PANEL_BORDER: #5D5151;
$COLOR_TECHROT_ACCENT: #E26E6A;
$COLOR_TECHROT_TITLE: $COLOR_TECHROT_ACCENT;
$COLOR_TECHROT_BORDER: #A63E3E;
$COLOR_TECHROT_TEXT: #FFFFFF;
$COLOR_TECHROT_LINK: $COLOR_TECHROT_TEXT;
$COLOR_TECHROT_LINK_ACTIVE: #EEACAA;
$COLOR_TECHROT_BUTTON: #04B491;

$COLOR_TECHROT_BACKGROUND_OVERLAY: #1F0000;

$GRADIENT_TECHROT_BACKGROUND: linear-gradient(to right, #0F0000, $COLOR_TECHROT_BACKGROUND_OVERLAY, #0F0000);
$GRADIENT_TECHROT_ABILITIES: linear-gradient(101.41deg, #4F1E1F, #220E0F);

$IMAGE_DIR: '/images/promo/techrot-encore/';

@import "../style-guide/_variables";
@import "../style-guide/_responsive";
@import "../style-guide/_mixins";
@import "../style-guide/_typography";

#warframeHome {
    background: $GRADIENT_TECHROT_BACKGROUND;
    --color-title: #{$COLOR_WHITE};
    --color-text: #{$COLOR_WHITE};
    --color-link: #{$COLOR_WHITE};
    --color-link-hover: #{$COLOR_TECHROT_LINK_ACTIVE};
    --masthead-overlay-color: #{$COLOR_TECHROT_BACKGROUND};

    .Button {
        &--primary {
            &:not(.PrimeAccessPanel-buyButton):not(.PrimeResurgencePanel-buyButton) {
                border-radius: 0;
                --color-button-background: #{$COLOR_TECHROT_BUTTON};
                --color-button-border: #{$COLOR_TECHROT_BUTTON};
                --color-button-text: #{$COLOR_TEXT};
                --color-button-background-hover: #{$COLOR_TECHROT_TEXT};
                --color-button-border-hover: #{$COLOR_TECHROT_TEXT};
                --color-button-text-hover: #{$COLOR_TEXT};
            }
        }
        &--secondary {
            &:not(.PrimeResurgencePanel-learnButton) {
                border-radius: 0;
                --color-button-background: #{rgba($COLOR_BLACK, 0.75)};
                --color-button-border: #{$COLOR_TECHROT_BUTTON};
                --color-button-text: #{$COLOR_TECHROT_BUTTON};
                --color-button-background-hover: #{$COLOR_BLACK};
                --color-button-border-hover: #{$COLOR_TECHROT_TEXT};
                --color-button-text-hover: #{$COLOR_TECHROT_TEXT};
            }
        }
    }
}

#masthead {
    .Masthead-background {
        @include breakpoint-desktop-up {
            &:after {
                content: "";
                position: absolute;
                bottom: -1px;
                left: 0;
                right: 0;
                height: 30%;
                background: bottom / auto 100% repeat-x url(#{$IMAGE_DIR}divider-masthead.png);
            }
        }
    }
}

.HomeNewsTile {
    --color-link-hover: #{$COLOR_TECHROT_LINK_ACTIVE};
}
.HomeNewsSnippet {
    --color-news-snippet-title: #{$COLOR_TECHROT_TEXT};
    --color-news-snippet-text: #{$COLOR_TECHROT_LINK};
    --color-link: #{$COLOR_WHITE};
    --color-link-hover: #{$COLOR_TECHROT_LINK_ACTIVE};

    &:hover,
    &:active {
        --color-news-snippet-title: #{$COLOR_TECHROT_LINK};
        --color-news-snippet-text: #{$COLOR_TECHROT_LINK_ACTIVE};
    }
}
.PrimeAccessDesktopBackground,
.PrimeResurgenceDesktopBackground {
    @include breakpoint-desktop-up {
        &:after {
            content: "";
            @include FULL_COVER;
            pointer-events: none;
            background: center / cover no-repeat url(#{$IMAGE_DIR}overlay-scanlines.jpg);
            mix-blend-mode: multiply;
            opacity: 0.3;
        }
    }
}
.PlatinumPacksPanel {
    &-content {
        background: left / cover no-repeat url(#{$IMAGE_DIR}bg-grunge.jpg);
        border: solid 1px $COLOR_TECHROT_PANEL_BORDER;
        @include breakpoint-tablet-down {
            border-radius: 0 0 $SPACE_SMALLER $SPACE_SMALLER;
            border-top-width: 0;
        }
        @include breakpoint-desktop-up {
            border-radius: $SPACE_SMALLER 0 0 $SPACE_SMALLER;
            border-right-width: 0;
        }
    }
    &-pretitle {
        color: $COLOR_WHITE;
    }
    &-title {
        color: $COLOR_WHITE;
    }
}
.PrimeResurgenceSection {
    &:after {
        @include breakpoint-tablet-down {
            background-color: $COLOR_TECHROT_BACKGROUND;
        }
    }
}
.PrimeResurgenceTabletMobilePanel {
    color: $COLOR_WHITE;
}
.PrimeResurgenceBonusSection {
    @include breakpoint-tablet-down {
        background: $COLOR_TECHROT_BACKGROUND;
    }
}
.MerchSection {
    .Section-background {
        @include breakpoint-tablet-down {
            mask-image: linear-gradient(to bottom, transparent, black 10%, black 90%, transparent);
        }
    }
}
.MerchPanel {
    background: center / cover no-repeat url(#{$IMAGE_DIR}bg-grunge.jpg);
    border: solid 1px $COLOR_TECHROT_PANEL_BORDER;
    border-radius: $SPACE_SMALLER;

    &-pretitle {
        color: $COLOR_WHITE;
    }
    &-title {
        color: $COLOR_WHITE;
    }
}

.BackgroundOverflowContainer {
    @include FULL_COVER;
    overflow: hidden;
}

#news {
    .Section-wrapper {
        overflow: visible;
    }
}
#merch {
    @include breakpoint-desktop-up {
        z-index: 2;
    }
    .Section-wrapper {
        overflow: visible;
    }
}
.Tendrils,
.AnimatedSpeaker {
    position: absolute;
    background: center / contain no-repeat;
    pointer-events: none;

    @include breakpoint-tablet-down {
        display: none;
    }
}
.Tendrils {
    &[data-index="0"] {
        background-image: url(#{$IMAGE_DIR}tendril2.png);
        aspect-ratio: 3453/2962;
        bottom: -210px;
        left: -96px;
        width: 768px;
    }
    &[data-index="1"] {
        background-image: url(#{$IMAGE_DIR}tendril1.png);
        aspect-ratio: 3558/3338;
        bottom: -240px;
        right: -211px;
        width: 768px;
    }
    &[data-index="2"] {
        background-image: url(#{$IMAGE_DIR}tendril2.png);
        aspect-ratio: 3453/2962;
        bottom: -250px;
        left: -120px;
        width: 768px;
    }
}
@keyframes speaker-pulse {
    0% {
        transform: scale(1);
        filter: blur(0);
    }
    25% {
        transform: scale(1.1);
        filter: blur(2px);
    }
    50% {
        transform: scale(1);
        filter: blur(0);
    }
}
.AnimatedSpeaker {
    animation: speaker-pulse 500ms infinite ease-out;

    &[data-index="0"] {
        background-image: url(#{$IMAGE_DIR}speaker3.png);
        aspect-ratio: 610/780;
        top: -200px;
        left: -77px;
        width: 192px;
    }
    &[data-index="1"] {
        background-image: url(#{$IMAGE_DIR}speaker1.png);
        aspect-ratio: 816/994;
        top: -180px;
        right: -100px;
        width: 210px;
        z-index: 1;
    }
    &[data-index="2"] {
        background-image: url(#{$IMAGE_DIR}speaker2.png);
        aspect-ratio: 1010/1180;
        top: -40px;
        left: -100px;
        width: 220px;
        z-index: 1;
    }
}
